import { recordToQueryString } from "../util/urls";

import {
  EMenu,
  EMenuItemStyle,
  EMenuItemType,
  IGetMenuOptions,
  IMenu,
  isMenuItemLink,
  TMenuMap,
} from "./usePopoverMenuStore.types";

type TPublicConfig = {
  NEXT_PUBLIC_FUNNEL_URL: string;
  NEXT_PUBLIC_WEBSITE_URL: string;
};

const THINKING_OF_CANCELLING_TITLE = "Thinking of cancelling?";
const EDIT_NEXT_DELIVERY_DATE_TITLE = "Edit next delivery date";

export const makeMenus = (
  PublicConfig: TPublicConfig,
  options: IGetMenuOptions | undefined = undefined,
): TMenuMap => ({
  [EMenu.MANAGE_FRESH_PLAN]: makeManageFreshPlanMenu(options, PublicConfig),
  [EMenu.MANAGE_SCOOP_HEALTH_PLAN]: makeManageScoopHealthPlanMenu(options, PublicConfig),
  [EMenu.MANAGE_SCOOP_PLANET_PLAN]: makeManageScoopPlanetPlanMenu(options, PublicConfig),
});

function makeManageFreshPlanMenu(options: IGetMenuOptions | undefined, PublicConfig: TPublicConfig) {
  return {
    title: "Your plan. your way",
    items: [
      {
        type: EMenuItemType.LINK,
        title: EDIT_NEXT_DELIVERY_DATE_TITLE,
        description: "Change when your next fresh food box arrives.",
        style: EMenuItemStyle.DEFAULT,
        url: `/customer/my-plans/delay/${options?.params?.subscriptionId}`,
      },
      {
        type: EMenuItemType.LINK,
        title: "Edit delivery frequency",
        description: "Change how often you receive fresh food boxes.",
        style: EMenuItemStyle.DEFAULT,
        url: "/customer/subscriptions/fresh/delivery-frequency/edit",
      },
      {
        type: EMenuItemType.LINK,
        title: "Update portion sizes",
        description: "Increase or decrease daily calories.",
        style: EMenuItemStyle.DEFAULT,
        url: "/customer/subscriptions/fresh/portion-size/edit",
      },
      {
        type: EMenuItemType.LINK,
        title: "Change cat details",
        description: "Update info like age, weight + allergies.",
        style: EMenuItemStyle.DEFAULT,
        url: `${PublicConfig.NEXT_PUBLIC_FUNNEL_URL}/pet-details`,
      },
      {
        type: EMenuItemType.LINK,
        title: "Amend recipe selection",
        description: "Mix up recipes. Or try something new.",
        style: EMenuItemStyle.DEFAULT,
        url: `${PublicConfig.NEXT_PUBLIC_FUNNEL_URL}/change-recipes`,
      },
      {
        type: EMenuItemType.LINK,
        title: "Manage extras",
        description: "Add tasty treats + meal toppers.",
        style: EMenuItemStyle.DEFAULT,
        url: `${PublicConfig.NEXT_PUBLIC_FUNNEL_URL}/change-addons`,
      },
      {
        type: EMenuItemType.LINK,
        title: THINKING_OF_CANCELLING_TITLE,
        style: EMenuItemStyle.BASIC,
        url: "/pre-cancellation",
      },
    ] as const,
  };
}

function makeManageScoopHealthPlanMenu(options: IGetMenuOptions | undefined, PublicConfig: TPublicConfig) {
  return {
    title: "Need to make a change?",
    items: [
      {
        type: EMenuItemType.LINK,
        title: EDIT_NEXT_DELIVERY_DATE_TITLE,
        description: "Change when your next Scoop Health litter arrives.",
        style: EMenuItemStyle.DEFAULT,
        url: `/customer/my-plans/delay/${options?.params?.subscriptionId}`,
      },
      {
        type: EMenuItemType.LINK,
        title: "Change delivery frequency",
        description: "Change how often you get litter.",
        style: EMenuItemStyle.DEFAULT,
        url: "/customer/subscriptions/scoop/health/delivery-frequency/edit",
      },
      {
        type: EMenuItemType.LINK,
        title: "Thinking of cancelling?",
        style: EMenuItemStyle.BASIC,
        url: `${PublicConfig.NEXT_PUBLIC_WEBSITE_URL}/pre-cancellation-scoop-health`,
      },
    ] as const,
  };
}

function makeManageScoopPlanetPlanMenu(options: IGetMenuOptions | undefined, PublicConfig: TPublicConfig) {
  return {
    title: "Your plan. your way.",
    items: [
      {
        type: EMenuItemType.LINK,
        title: EDIT_NEXT_DELIVERY_DATE_TITLE,
        description: "Change when your next Scoop Planet litter arrives.",
        style: EMenuItemStyle.DEFAULT,
        url: `/customer/my-plans/delay/${options?.params?.subscriptionId}`,
      },
      {
        type: EMenuItemType.LINK,
        title: "Change delivery frequency",
        description: "Change how often you get litter.",
        style: EMenuItemStyle.DEFAULT,
        url: "/customer/subscriptions/scoop/planet/delivery-frequency/edit",
      },
      {
        type: EMenuItemType.LINK,
        title: THINKING_OF_CANCELLING_TITLE,
        style: EMenuItemStyle.BASIC,
        url: `${PublicConfig.NEXT_PUBLIC_WEBSITE_URL}/pre-cancellation-scoop-planet`,
      },
    ] as const,
  };
}

export const getPopoverMenu = <T extends EMenu>(
  menu: T,
  PublicConfig: TPublicConfig,
  options: IGetMenuOptions | undefined = undefined,
): IMenu<T> => {
  const menus = makeMenus(PublicConfig, options);
  const menuDetails = menus[menu];

  return {
    ...menuDetails,
    items: menuDetails.items.map((item) => (
      isMenuItemLink(item) ?
        ({ ...item, url: `${item.url}${recordToQueryString(options?.searchParams as Record<string, string | number | boolean> | undefined)}`, options }) :
        item
    )),
  };
};
