export const recordToQueryString =
(record?: Record<string, string | number | boolean>, withQueryStringInitiator = true) => {
  if (!record) {
    return "";
  }
  const queryParamsAsString: string[] = [];
  Object.entries(record).forEach(([ key, value ]) => {
    queryParamsAsString.push(`${key}=${value}`);
  });

  return `${withQueryStringInitiator ? "?" : ""}${queryParamsAsString.join("&")}`;
};
